import React, {Component, useEffect, useState} from "react";

//import Select from 'react-select'
import {useLocation} from '@reach/router';
import {Link, navigate} from "gatsby"
//import {swipeTransition, clubs} from "../constants"
//import * as styles from '../styles/index.module.scss'

import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'

const MethodologyPage = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);
  const [methodology, setMethodology] = useState("");

  useEffect(() => {

    fetch("https://lead5050.staging.parachute.net/wp-json/wp/v2/pages/?include[]=84018")
      .then(response => response.json())
      .then(data => {
        setMethodology(data);
      }) 

  })

const renderData = () => {
    if (methodology != null){
        if (methodology[0] != null){
    var method = methodology[0].content.rendered
    return(
        <>
        
        <div dangerouslySetInnerHTML={{__html: method}} />
        </>
    )
    }
}
}

  return (

    <Layout pageTitle="Home Page">

<div className="container">
	<div className="row">
		<div className="col-md-12">
			
			<div className="container">
				<div className="row justify-content-center align-items-center">
					<div className="col-md-9">
						<div className="user-content text-center block-padding-medium">
							<h1>Methodology</h1>
						</div>
					</div>
				</div>

        
			</div>
      </div>
      </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-align-center">
        {renderData()}

          </div>
        </div>
      </div>
    </Layout>
  ) 
}
export default MethodologyPage